
.kielenvalinta-container {
    position: absolute;
    right: 5px;
    top: 15px;
}
.kielenvalinta-container button {
	color: #0062cc !important;
	background-color: #fff !important;
	border: 0 !important;
}
.kielenvalinta-container button:before {
	background-color: #fff !important;
}