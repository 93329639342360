.palautelahetetty-content {
    max-width: 550px;
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.palautelahetetty-icon {
    font-size: 33px !important;
    color: #0eb24c;
}

.palautelahetetty-text {
    margin-left: 20px;
    margin-top: 15px;
}

.piiloita-kayttajalta {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}