.ohituslinkki {
    list-style-type: none;
    position: absolute;
    top: -3em;
    padding: 0.5em 0.75em;
    text-decoration-line: underline;
}

.ohituslinkki:focus {
    top: 1em;
    left: 1em;
}