.virhe-content {
    max-width: 550px;
}
.virhe-error-icon {
    display: inline-block;
    font-size: 25px !important;
}
.virhe-error-text {
    display: inline-block;
    margin-left: 20px;
}